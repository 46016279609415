import { useContext } from "react"
import { FlowsSharedContext } from "./Flows"

export const useFlowsConfig = (key: string): [
    object | undefined, (value: object) => void
] => {
    const config: Map<string, object> = useContext(FlowsSharedContext).config;

    const setConfig = (value: object) => {
        config.set(key, value);
    }

    const getConfig = () => {
        if (!config.has(key)) return undefined;
        return config.get(key)
    }

    return [getConfig, setConfig]; 
}

export default useFlowsConfig;